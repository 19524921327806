import axios from "axios";
import router from "@/router";
import {TokenStorage} from "@/services/TokenStorage";
import IAM from "@/services/api/IAM.js";
import  store  from "@/store";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env["VUE_APP_API_URL"];
const LOGIN_ROUTE = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/device-management/protocol/openid-connect/token`;
axiosInstance.interceptors.request.use((config) => {
  store.commit("global/SET_LOADING", true);
  config.headers = {...config.headers, ...TokenStorage.getAuthentication().headers}
  return config;
});
axiosInstance.interceptors.response.use((response) => {
  store.commit("global/SET_LOADING", false);

  return response
}, (error) => {
  store.commit("global/SET_LOADING", false);
  console.log(error.response.data)
  store.dispatch('global/addAlert', {
    type: 'error',
    message: error.response.data
  }, {root: true});
  if(error.response.status === 400 && error.response.data.message === 'action expired'){
    store.commit("SET_MESSAGE_TYPE", "LinkExpired");
    router.push({name: 'Success'})
  }
  if(error.response.status === 409){
    store.commit("SET_LOCATION_DISPLAY_ID", error.response.data.message);
  }
  if(error.response.status === 400 && error.response.data.message === 'invalid token'){
    store.commit("SET_MESSAGE_TYPE", "LinkExpired");
    router.push({name: 'Success'})
  }
  if(error.response.status === 400 && error.response.data.error_description === 'Account is not fully set up'){
    const userEmail = store.getters.getUserEmail; // Replace with actual getter name
    return IAM.passwordUpdate(userEmail).then(() =>{ 
      store.commit("SET_MESSAGE_TYPE", "AccountNotFullySetUp");
      router.push({name: 'Success'})
    })
  }
  if(error.response.status === 400 && error.response.data.email[0] === "Enter a valid email address."){
    store.commit("global/SET_LOADING", false);
    store.commit("SET_MESSAGE_TYPE", "EmailError");
  }
  if(error.response.status === 404 && error.response.data.message === 'space-account not found'){
    store.commit("SET_MESSAGE_TYPE", "UserNotAllowed");
      router.push({name: 'Success'})
  }
  if(error.response.status === 405){
    store.commit("global/SET_LOADING", false);
    if(error.response.data.message === "Not allowed. Reset password request has been already sent. Please check your e-mail inbox."){
      store.commit("SET_MESSAGE_TYPE", "MethodNotAlloved");
      router.push({name: 'Success'})
    }else if(error.response.data.message === "Not allowed for non-active accounts"){
      store.commit("global/SET_LOADING", false);
      store.commit("SET_MESSAGE_TYPE", "UserNonExist");
    }
  }
  if(error.response.status === 503 && error.response.data.message === 'This location has no available devices'){
      store.commit("devices/SET_NO_DETECT_DEVICES", true);
      store.commit("locations/SET_DETECTED_DEVICES", [])
  }
  if(error.response.status === 503 && error.response.data.message === 'physical device temporarily unavailable'){
    store.dispatch('global/addAlert', {
      type: 'error',
      message: error.response.data.message
    }, {root: true});
  }
  if (error.message !== "Missing auth") {
    if (error.response.status === 422) {
      TokenStorage.clear();
      router.push({name: 'Login'})
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.config.url === LOGIN_ROUTE) {
      TokenStorage.clear();
      router.push({name: 'Login'})
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    return TokenStorage.getNewAccessToken().then((token) => {
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`
      return new Promise((resolve, reject) => {
        axiosInstance.request(config).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }).catch(error => {
      store.commit("global/SET_LOADING", false);
      Promise.reject(error);
    })
  } else {
    Promise.reject(error);
  }
});

export default axiosInstance;
